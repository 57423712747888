<script setup>
/* eslint-disable */
import { ref, watch } from 'vue';
import { useFixtureStore } from '@/stores/fixture';
import { useLiveSportsStore } from '@/stores/live_sports';
import { useRouter } from 'vue-router';
import { computed } from 'vue';

const router = useRouter();
const fixtureStore = useFixtureStore();
const liveSportsStore = useLiveSportsStore();

const is_muted = ref(false);

const toggleMute = () => {
	is_muted.value = !is_muted.value;
};

const goToCompetition = (fixture) => {
	router.push({ name: 'pre-sports-competition', params: { sport_id: fixture.sport_id, region_id: Number(fixture.region_id), competition_id: fixture.competition_id } } );
}

const showResult = (result) => {
	if (result === undefined) return 'penalty-wait';
	else if (result) return 'penalty-score';
	else return 'penalty-miss';
};

const checkBiggerArray = computed(() => {
	let player_1 = [];
	let player_2 = [];
	if (fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p1) player_1 = fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p1;
	if (fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p2) player_2 = fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p2;

	if (player_1.length > player_2.length) return player_1.slice(5).length;
	else return player_2.slice(5).length;
})

watch(() => fixtureStore.fixtureBook.statistics, (newValue, oldValue) => {
	// If Score
	if (newValue && oldValue && (newValue.p1_score !== oldValue.p1_score || newValue.p2_score !== oldValue.p2_score)) {
		playSoundNotification('score');
	}

	// If Starting
	if (oldValue && oldValue.half == null && newValue && newValue.half == 'H1') {
		playSoundNotification('start');
	// If HalfTime
	} else if (oldValue && oldValue.half == 'H1' && newValue && newValue.half == 'HT') {
		playSoundNotification('half-time');
	} else if (oldValue && oldValue.half == 'H1' && newValue && newValue.half == 'H2') {
		playSoundNotification('half-time');
	}
});

const playSoundNotification = (notification_type) => {
	if (!is_muted.value) {
		let sound_file = '';
		if (notification_type == 'score') {
			sound_file = '/assets/scoreboards/notification-sounds/goal.mp3';
		}else if (notification_type == 'start' || notification_type == 'half-time') {
			sound_file = '/assets/scoreboards/notification-sounds/start_and_half_time.mp3';
		}
		if (sound_file) {
			const goalSound = new Audio(sound_file);
			goalSound.autoplay = true;
		}
	}
}
</script>

<template>
<div class="eventview-game-banner" :style="{ height: fixtureStore.fixtureBook.scoreboard.penalty_period && fixtureStore.fixtureBook.scoreboard.penalty_period.score && fixtureStore.fixtureBook.scoreboard.penalty_period.penalties ? '250px' : '' }">
	
	<div class="live-game-outer">
		<div class="live_score_Box">
			<div class="league-title" style="cursor:pointer !important;">
				
				<h6 @click="goToCompetition(fixtureStore.fixtureBook)">{{ fixtureStore.fixtureBook.region_name + ' - ' + fixtureStore.fixtureBook.competition_name }}</h6>
			</div>

			<div @click="toggleMute" style="cursor: pointer;">
				<div v-if="is_muted" id="sound-icon" class="sound-notification">
					<i class='fas fa-volume-mute'></i>
				</div>
				<div v-else id="sound-icon" class="sound-notification">
					<i class='fas fa-volume-up'></i>
				</div>
			</div>
			<!-- score panel start-->
			<div class="league-score">
				<div class="playing-time" v-if="fixtureStore.fixtureBook.scoreboard.penalty_period">
					<p>{{ $t('scoreboard.penalties') }}</p>
					<span></span>
				</div>
				<div v-else class="playing-time">
					<p>{{ fixtureStore.fixtureClockTime(fixtureStore.fixtureBook) }} {{ fixtureStore.fixtureState(fixtureStore.fixtureBook) }}</p>
					<span></span>
				</div>

				<div class="eventview-playing-info">
					<div class="playing-team-inner">
						<div class="playing-team">
							<h6>{{ fixtureStore.fixtureBook.participants[0] }}</h6>
							<!-- <img src="@/assets/images/leeds.png" alt=""> -->
						</div>
						<div class="playing-score">
							<h3>{{ fixtureStore.fixtureBook.statistics.p1_score }}</h3>
						</div>
					</div>

					<div class="playing-team-inner">
						<div class="playing-team">
							<!-- <img src="@/assets/images/liverpool.png" alt=""> -->
							<h6>{{ fixtureStore.fixtureBook.participants[1] }}</h6>
						</div>
						<div class="playing-score">
							<h3>{{ fixtureStore.fixtureBook.statistics.p2_score }}</h3>
						</div>
					</div>
				</div>

				<div class="live-foul-info">

					<Popper arrow hover openDelay="100" closeDelay="100" placement="top">
						<template #content>
							<div class="tooltip">{{ $t('scoreboard.red_cards') }}</div>
						</template>

						<div class="live-foul-inner">
							<div class="live-foul-left">
								<p>{{ fixtureStore.fixtureBook.scoreboard.red_cards?.p1 }}</p>
							</div>
							<div class="live-foul-center">
								<svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10">
									<rect id="Rectangle_271" data-name="Rectangle 271" width="6" height="10" fill="#f50019"></rect>
								</svg>
							</div>
							<div class="live-foul-right">
								<p>{{ fixtureStore.fixtureBook.scoreboard.red_cards?.p2 }}</p>
							</div>
						</div>
					</Popper>

					<Popper arrow hover openDelay="100" closeDelay="100" placement="top">
						<template #content>
							<div class="tooltip">{{ $t('scoreboard.yellow_cards') }}</div>
						</template>

						<div class="live-foul-inner">
							<div class="live-foul-left">
								<p>{{ fixtureStore.fixtureBook.scoreboard.yellow_cards?.p1 }}</p>
							</div>
							<div class="live-foul-center">
								<svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10">
									<rect id="Rectangle_271" data-name="Rectangle 271" width="6" height="10" fill="#FFBB02"></rect>
								</svg>
							</div>
							<div class="live-foul-right">
								<p>{{ fixtureStore.fixtureBook.scoreboard.yellow_cards?.p2 }}</p>
							</div>
						</div>
					</Popper>

					<Popper arrow hover openDelay="100" closeDelay="100" placement="top">
						<template #content>
							<div class="tooltip">{{ $t('scoreboard.corners') }}</div>
						</template>

						<div class="live-foul-inner">
							<div class="live-foul-left">
								<p>{{ fixtureStore.fixtureBook.scoreboard.corners?.p1 }}</p>
							</div>
							<div class="live-foul-center">
								<svg xmlns="http://www.w3.org/2000/svg" width="6.294" height="10" viewBox="0 0 6.294 10">
									<g id="Group_110" data-name="Group 110" transform="translate(-1729.05 -155)">
										<g id="corner_1_" data-name="corner (1)" transform="translate(1713 148.38)">
											<path id="Path_259" data-name="Path 259" d="M275.7,30.09,270.307,28v4.535l5.394-2.093A.187.187,0,0,0,275.7,30.09Z" transform="translate(-253.477 -20.794)" fill="#f50019"></path>
											<path id="Path_261" data-name="Path 261" d="M242.473,16.62h-.78V7.01a.39.39,0,0,1,.39-.39h0a.39.39,0,0,1,.39.39Z" transform="translate(-225.643)" fill="#fff"></path>
										</g>
									</g>
								</svg>
							</div>
							<div class="live-foul-right">
								<p>{{ fixtureStore.fixtureBook.scoreboard.corners?.p2 }}</p>
							</div>
						</div>
					</Popper>
				</div>
			</div>

			<div v-if="fixtureStore.fixtureBook.scoreboard.penalty_period && fixtureStore.fixtureBook.scoreboard.penalty_period.score && fixtureStore.fixtureBook.scoreboard.penalty_period.penalties" class="penalty-scorecard-outer">
				<div class="penalty-scorecard-inner-main">
					<div class="penalty-scorecard-inner">
						<div class="team-name"></div>
						<div class="penalty-scorecard-score-outer">
							<div class="penalty-scorecard-score-inner">
								<Popper arrow hover openDelay="100" closeDelay="100" placement="top">
									<template #content>
										<div class="tooltip"> {{ $t('scoreboard.penalty') }} {{ $t('scoreboard.score') }} </div>
									</template>
									<h6>S</h6>
								</Popper>
							</div>
							<div class="penalty-scorecard-score-inner">
								<Popper arrow hover openDelay="100" closeDelay="100" placement="top">
									<template #content>
										<div class="tooltip"> {{ $t('scoreboard.penalty') }} 1 </div>
									</template>
									<h6>1</h6>
								</Popper>
							</div>
							<div class="penalty-scorecard-score-inner">
								<Popper arrow hover openDelay="100" closeDelay="100" placement="top">
									<template #content>
										<div class="tooltip"> {{ $t('scoreboard.penalty') }} 2 </div>
									</template>
									<h6>2</h6>
								</Popper>
							</div>
							<div class="penalty-scorecard-score-inner">
								<Popper arrow hover openDelay="100" closeDelay="100" placement="top">
									<template #content>
										<div class="tooltip"> {{ $t('scoreboard.penalty') }} 3 </div>
									</template>
									<h6>3</h6>
								</Popper>
							</div>
							<div class="penalty-scorecard-score-inner">
								<Popper arrow hover openDelay="100" closeDelay="100" placement="top">
									<template #content>
										<div class="tooltip"> {{ $t('scoreboard.penalty') }} 4 </div>
									</template>
									<h6>4</h6>
								</Popper>
							</div>
							<div class="penalty-scorecard-score-inner">
								<Popper arrow hover openDelay="100" closeDelay="100" placement="top">
									<template #content>
										<div class="tooltip"> {{ $t('scoreboard.penalty') }} 5 </div>
									</template>
									<h6>5</h6>
								</Popper>
							</div>
							<template v-if="(fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p1 && fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p1.length > 5) || (fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p2 && fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p2.length > 5)">
								<div class="penalty-scorecard-score-inner" v-for="(penalty, index) in checkBiggerArray" :key="index">
									<Popper arrow hover openDelay="100" closeDelay="100" placement="top">
										<template #content>
											<div class="tooltip"> {{ $t('scoreboard.penalty') }} {{ index + 6 }} </div>
										</template>
										<h6>{{ index + 6 }}</h6>
									</Popper>
								</div>
							</template>
						</div>
					</div>
					<div class="penalty-scorecard-inner">
						<div class="team-name">
							<h5>{{ fixtureStore.fixtureBook.participants[0] }}</h5>
						</div>
						<div class="penalty-scorecard-score-outer">
							<div class="penalty-scorecard-score-inner" v-if="fixtureStore.fixtureBook.scoreboard.penalty_period.score.p1">
								<h6>{{ fixtureStore.fixtureBook.scoreboard.penalty_period.score.p1 }}</h6>
							</div>
							<div class="penalty-scorecard-score-inner">
								<div v-if="fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p1" :class="showResult(fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p1[0])"></div>
								<div v-else class="penalty-wait"></div>
							</div>
							<div class="penalty-scorecard-score-inner">
								<div v-if="fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p1" :class="showResult(fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p1[1])"></div>
								<div v-else class="penalty-wait"></div>
							</div>
							<div class="penalty-scorecard-score-inner">
								<div v-if="fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p1" :class="showResult(fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p1[2])"></div>
								<div v-else class="penalty-wait"></div>
							</div>
							<div class="penalty-scorecard-score-inner">
								<div v-if="fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p1" :class="showResult(fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p1[3])"></div>
								<div v-else class="penalty-wait"></div>
							</div>
							<div class="penalty-scorecard-score-inner">
								<div v-if="fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p1" :class="showResult(fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p1[4])"></div>
								<div v-else class="penalty-wait"></div>
							</div>
							<template v-if="(fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p1 && fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p1.length > 5) || (fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p2 && fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p2.length > 5)">
								<template v-for="(penalty, index) in checkBiggerArray" :key="index">
									<div class="penalty-scorecard-score-inner">
										<div v-if="fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p1" :class="showResult(fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p1[index + 5])"></div>
										<div v-else class="penalty-wait"></div>
									</div>
								</template>
							</template>
						</div>
					</div>
					<div class="penalty-scorecard-inner">
						<div class="team-name">
							<h5>{{ fixtureStore.fixtureBook.participants[1] }}</h5>
						</div>
						<div class="penalty-scorecard-score-outer">
							<div class="penalty-scorecard-score-inner" v-if="fixtureStore.fixtureBook.scoreboard.penalty_period.score.p2">
								<h6>{{ fixtureStore.fixtureBook.scoreboard.penalty_period.score.p2 }}</h6>
							</div>
							<div class="penalty-scorecard-score-inner">
								<div v-if="fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p2" :class="showResult(fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p2[0])"></div>
								<div v-else class="penalty-wait"></div>
							</div>
							<div class="penalty-scorecard-score-inner">
								<div v-if="fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p2" :class="showResult(fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p2[1])"></div>
								<div v-else class="penalty-wait"></div>
							</div>
							<div class="penalty-scorecard-score-inner">
								<div v-if="fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p2" :class="showResult(fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p2[2])"></div>
								<div v-else class="penalty-wait"></div>
							</div>
							<div class="penalty-scorecard-score-inner">
								<div v-if="fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p2" :class="showResult(fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p2[3])"></div>
								<div v-else class="penalty-wait"></div>
							</div>
							<div class="penalty-scorecard-score-inner">
								<div v-if="fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p2" :class="showResult(fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p2[4])"></div>
								<div v-else class="penalty-wait"></div>
							</div>
							<template v-if="(fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p1 && fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p1.length > 5) || (fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p2 && fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p2.length > 5)">
								<template v-for="(penalty, index) in checkBiggerArray" :key="index">
									<div class="penalty-scorecard-score-inner">
										<div v-if="fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p2" :class="showResult(fixtureStore.fixtureBook.scoreboard.penalty_period.penalties.p2[index + 5])"></div>
										<div v-else class="penalty-wait"></div>
									</div>
								</template>
							</template>
						</div>
					</div>
				</div>
			</div>
			<!-- score panel end-->
		</div>
	</div>
</div>
</template>
<style scoped>
svg.svg-inline--fa.fa-volume-up.fa-w-18 {
	position: absolute;
	right: 10px;
	top: 0px;
	color: #fff;
}
svg.svg-inline--fa.fa-volume-mute.fa-w-16  {
	position: absolute;
	right: 10px;
	top: 0px;
	color: #fff;
}
@media screen and (min-width: 320px) and (max-width: 991px) {
	svg.svg-inline--fa.fa-volume-up.fa-w-18 {
		top: 0px;
	}
	svg.svg-inline--fa.fa-volume-mute.fa-w-16  {
		top: 0px;
	}
}
html[dir="rtl"] svg.svg-inline--fa.fa-volume-up.fa-w-18 {
		right: unset;
		left: 10px
}
html[dir="rtl"] svg.svg-inline--fa.fa-volume-mute.fa-w-16 {
		right: unset;
		left: 10px
}

.tooltip:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}
.tooltip {
	width: 50px;
	background-color: #9DC137;
	padding: 5px 0;
	text-align: center;
	border: 1px solid #9DC137;
	border-radius: 50px !important;
	font-size: 10px;
	color:#ffffff
}
:deep(.popper) {
	background-color: #9DC137;
	padding: 20px;
	border-radius: 20px;
	font-weight: bold;
	margin-right: 30px;
}

:deep(.popper #arrow::before) {
	background: #9DC137;
	left: 0px;
}
:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
	background: #9DC137;
}
.penalty-scorecard-outer {
	-webkit-backdrop-filter: blur(3px) brightness(100%);
	backdrop-filter: blur(3px) brightness(100%);
	position: relative;
	max-width: 500px;
	margin: 15px auto;
}
.penalty-scorecard-inner {
	display: grid;
	grid-template-columns: 1fr auto;
	gap: 10px;
	border-bottom: 1px solid #777;
	align-items: center;
	min-height: 24px;
}

.penalty-scorecard-score-outer {
	display: flex;
	height: 100%;
	align-items: stretch;
}

.penalty-scorecard-score-inner {
	min-width: 25px;
	text-align: center;
	border-right: 1px solid #4E545D;
	display: flex;
	align-items: center;
	justify-content: center;
}
.penalty-scorecard-score-outer .penalty-scorecard-score-inner:first-child h6 {
	color: #9DC137;
}
.penalty-scorecard-score-inner:last-child {
	border-right: 0;
}
.penalty-scorecard-inner-main .penalty-scorecard-inner:last-child {
	border-bottom: 0;
}
.penalty-scorecard-score-inner h6 {
	font-size: 10px;
	font-weight: 100;
	color: #ffffff
}
.team-name h5 {
	font-size: 12px;
	font-weight: 100;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	padding-left: 28px;
}
.penalty-scorecard-inner .team-name h5 {
	font-size: 12px;
	font-weight: 100;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	padding-left: 28px;
	color: #ffffff;
}

.penalty-wait {
	width: 8px;
	height: 8px;
	background-color: #ffffff;
	border-radius: 50%;
}
.penalty-score {
	width: 8px;
	height: 8px;
	background-color: #9DC137;
	border-radius: 50%;
}
.penalty-miss {
	width: 8px;
	height: 8px;
	background-color: #F50019;
	border-radius: 50%;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
	.penalty-scorecard-outer {
		-webkit-backdrop-filter: none;
		backdrop-filter: none;
		min-width: 100%;
		overflow-x: auto;
		width: 100%;
		padding-bottom: 0px;
		max-width: unset;
	}
	.penalty-scorecard-inner-main {
		margin-bottom: 10px;
	}
	.penalty-scorecard-inner {
		grid-template-columns: 200px auto;
		min-width: 100%;
		width: -moz-max-content;
		width: max-content;
	}
	.penalty-scorecard-score-outer {
		justify-content: flex-end;
	}
}
</style>