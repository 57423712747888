<script setup>
/* eslint-disable */
import { usePromotionsStore } from '@/stores/promotions';
import { onMounted } from 'vue'
import { useRouter } from 'vue-router';

// Define stores
const router = useRouter();
const promotionsStore = usePromotionsStore();

const goToPromotion = (promotion_slug) => {
	router.push({ name: 'promotion', params: { promotion_slug: promotion_slug } });
}

onMounted(() => {
	if (Object.keys(promotionsStore.getPromotions).length == 0) promotionsStore.fetchPromotionCategories();
});
</script>

<template>
		<div class="promo-card-main">
			<div class="promo-card-inner"  style="cursor: pointer;">
				<div class="promo-content">
					<h3>Live <span>Sports</span> Betting</h3>
					<p>Bet on Football, Basketball, Horse Racing and many other sports</p>
					<a class="btn btn-secondary" href="#">Start playing!</a> 
				</div>
				<div class="promo-image">
					<img src="@/assets/images/promo-demo1.jpg" alt="">
				</div>
			</div>

			<div class="promo-card-inner"  style="cursor: pointer;">
				<div class="promo-content">
					<h3>Live <span>Dealers</span></h3>
					<p>Play your favorite games with live dealers</p>
					<a class="btn btn-secondary" href="#">Start playing!</a> 
				</div>
				<div class="promo-image">
					<img src="@/assets/images/promo-demo2.jpg" alt="">
				</div>
			</div>

		</div>
</template> 
<style scoped>
html[dir="rtl"] .promo-card-main .promo-card-inner .promo-content img {
	margin-left: 5px;
	margin-right: unset;
	rotate: 180deg;
}

.promo-card-main {
	scrollbar-width: none !important;
}
</style>