<!-- eslint-disable -->
<script setup>
import { useI18n } from 'vue-i18n';
import { useRegisterStore } from '../../stores/register';

const { t } = useI18n();
const registerStore = useRegisterStore();
</script>

<template>
	<div class="register-form-field form-field-check">
		<div class="register-age-check">
			<input type="checkbox" v-model="registerStore.register_input_fields.tc_check" @change="registerStore.validateTcCheck" id="age_check" name="age_check" value="age">	
			<label for="age_check">
				<p>{{ $t('account.register.i_agree_with_all') }} <a href="/static/page/general-terms-and-conditions">{{ $t('account.register.terms_and_conditions') }}</a> {{ $t('account.register.rules') }} <a href="/static/page/privacy-policy">{{ $t('account.register.privacy') }}</a></p>
			</label>
		</div>
		<div class="register-form-list" v-if="registerStore.register_errors.tc_check.error">
			<div class="password-meter">
				<div class="password-meter-bar">
					<div class="password-meter-bar-inn"></div>
				</div>
			</div> 
			<p>
				<img src="@/assets/svgs/cross.svg" alt=""/>
				&nbsp;
				<span v-if="registerStore.register_errors.tc_check.error == 'error_tc_check'">
					{{ $t('account.register.error_tc_check') }}
				</span>
				<span v-else-if="registerStore.register_errors.hasOwnProperty('tc_check')">
					{{ registerStore.register_errors.tc_check.error }}
				</span>
			</p>
		</div>
	</div>
</template>

<style scoped>
.register-form-main .register-outer-form .register-inner-form .password-meter .password-meter-bar {
	background-color:#FF6B73;
	margin-bottom:5px;
}
.register-form-main .register-form-list {
	margin-top:unset;
	padding-top:5px;
}

html[dir="rtl"] .register-form-main .register-age-check #age_check + label:before {
	margin-left:10px;
}
html[dir="rtl"] .register-form-main .register-age-check p {
	text-align: right;
}
html[dir="rtl"] .register-form-main .register-age-check #age_check:checked + label:after {
	left: unset;
	right: 15px;
}
</style>