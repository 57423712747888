<!-- eslint-disable -->
<script setup>
import { ref, onMounted, computed} from 'vue';
import { closeModal, confirmModal } from '@kolirt/vue-modal'
import { useLanguagesStore } from '../../stores/languages';
import { useCurrenciesStore } from '../../stores/currencies';
import { useCountriesStore } from '../../stores/countries';
import { useRegisterStore } from '../../stores/register';
import { useCustomerStore } from '../../stores/customer';
import { useGeneralStore } from '@/stores/general';
import { useI18n } from 'vue-i18n';
import _ from 'lodash';
import commonSvgs from '@/assets/svgs/commonSvgs.json'

const svg_data = commonSvgs;
const { t } = useI18n();
const customerStore = useCustomerStore();
const languagesStore = useLanguagesStore();
const countriesStore = useCountriesStore();
const currenciesStore = useCurrenciesStore();
const registerStore = useRegisterStore();
const generalStore = useGeneralStore();
const current_step = ref(1);
const password_visibility = ref(false);
const step_count = ref(1);
const registration_completed = ref(false);
const loading = ref(false);


const is_country_ghana = process.env.VUE_APP_COUNTRY == 'GHANA';

const incrementCurrenctStep = () => {
	let validated = registerStore.validateStep(current_step.value)
	if (current_step.value == registerStore.getAllSteps ) {
		submitRegister()
	}else if (validated) {
		current_step.value++;
	}
}

const decrementCurrentStep = () => {
	current_step.value--;
}

const changeStep = (step) => {
	let validated;
	// Check if the step is greater than the current step, if validation passes, increment the current step
	if (current_step.value < step) {
		validated = registerStore.validateStep(current_step.value);
		// If validation passes, increment the current step
		if (validated) current_step.value++;
		// If the step is not greater and the current step decrement the current step
	} else if (current_step.value > 1) {
		current_step.value--;
	}
}

function getCookieValue(name) {
	let cookie_array = document.cookie.split(';');
	for (let i = 0; i < cookie_array.length; i++) {
		let cookie_pair = cookie_array[i].split('=');
		if (name == cookie_pair[0].trim()) {
			return decodeURIComponent(cookie_pair[1]);
		}
	}

	return null;
}

const submitRegister = () => {
	const enabled_fields = registerStore.getEnabledRegistrationFields;
	let payload = {};

	if (is_country_ghana) {
		if (!registerStore.register_input_fields.username) {
			let mobile_number = registerStore.register_input_fields.mobile_number;

			// If first digit is 0, remove it
			if (mobile_number.startsWith('0')) {
				mobile_number = mobile_number.substring(1);
			}

			payload['username'] = mobile_number;
		}
	}

	for (const field of enabled_fields) {
		if (registerStore.register_input_fields[field.slug]) {
			payload[field.slug] = registerStore.register_input_fields[field.slug];
		}
	}

	const dob = _.find(enabled_fields, { slug: 'dob' });

	if (dob){
		payload['dob_day'] = registerStore.register_input_fields.dob_day;
		payload['dob_month'] = registerStore.register_input_fields.dob_month;
		payload['dob_year'] = registerStore.register_input_fields.dob_year;
	}

	payload['password_confirmation'] = registerStore.register_input_fields.password_confirmation;

	// Set affiliate cookies
	if (getCookieValue('af_btag') !== null) payload['btag'] = getCookieValue('af_btag');
	if (getCookieValue('af_link') !== null) payload['link'] = getCookieValue('af_link');
	if (getCookieValue('af_banner') !== null) payload['banner'] = getCookieValue('af_banner');


	loading.value = true;

	registerStore.submitRegister(payload).then((response) => {
		if (customerStore.auth_token) {
			global.axios.defaults.headers['Authorization'] = 'Bearer ' + customerStore.auth_token;
			customerStore.fetchCustomer();
		}

		loading.value = false;
		current_step.value++;

		registration_completed.value = true;
		registerStore.initializeForm();
	}).catch(error => {
		if (error && error.data && error.data.errors) {
				current_step.value = registerStore.getFieldStep(Object.keys(error.data.errors)[0]);
				loading.value = false
				return error.data.message;
		}	else {
				loading.value = false;
			}
	});
}

const number_verified = ref(false);
const isRegisterButtonDisabled = computed(() => {
	if (registerStore.hasNumberVerification == true && current_step.value == registerStore.getAllSteps) {
		if (number_verified.value == false) {
			return true;
		} else {
			return false;
		}
	}

	return false;
});

</script>
<template>
	<section class="register-panel">
		<div class="container-full">
			<div class="register-panel-main">
				<span class="form-cross" >
					<a @click="closeModal" style="cursor: pointer;">
						<object v-html="svg_data.form_cross"></object>
					</a>
					</span>
					<div class="register-panel-left">
				<div class="register-image-box">

					<div class="register-logo">
						<div class=""></div>
						<img :src="generalStore.getGeneral.header_logo" alt="register-logo" @click="closeModal">
					</div>

					<div class="content">
							<h3>the best <span>betting</span> experience</h3>
						</div>

				</div>
			</div>

				<div class="register-panel-right">

					<div v-if="!registration_completed" id="multi-step-form-container" class="register-form-main">
						<h2>{{ $t('account.register.create_account') }}</h2>
						<!-- Form Steps / Progress Bar -->
						<ul class="form-register form-register-horizontal">
							<!-- Step 1 -->
							<li class=" form-register-list"  v-for="step_count in registerStore.getAllSteps" :key="step_count" :class="current_step  == step_count ? 'form-register-active' : 'form-register-unfinished'" @click="changeStep(step_count)" style="cursor: pointer;" :step="step_count">
								<a class="">
									<span class="form-register-circle text-muted">
										<span>{{step_count}}</span>
									</span>
								</a>
							</li>
						</ul>
						<!-- Step Wise Form Content -->
						<form class="register-outer-form" id="userAccountSetupForm" name="userAccountSetupForm"
							enctype="multipart/form-data" method="POST">
							<section v-for="step_count in registerStore.getAllSteps" :key="step_count" class="form-step">
									<template v-if="current_step == step_count">
										<div class="close_btn" style="cursor: pointer;">
											<a href="#" @click="closeModal" class="form-mobile-back-close">
												<object v-html="svg_data.arrow_left"></object>
											</a>
										</div>

										<div class="register-inner-form">

										<template v-for="field in registerStore.getStepFieldComponents(step_count)" :key="field">
											<component :is="field"></component>
										</template>
										</div>

										<div v-if="step_count > 1" class="form-btns">
												<button @click="decrementCurrentStep" class="btn btn-navigate-form-step btn-form-prev" type="button"
													step_number="1">{{ $t('general.back') }}</button>
												<button v-if="!loading" @click="incrementCurrenctStep()" class="btn btn-secondary btn-navigate-form-step" type="button">
													{{ (current_step == registerStore.getAllSteps) ? $t('account.register.create_account') : $t('general.next') }}&nbsp;</button>
												<button v-else class="btn btn-secondary btn-navigate-form-step" type="button"><div class="loader-main"></div></button>
										</div>
										<div v-else class="form-btn-single">
												<button @click="incrementCurrenctStep()" class="btn btn-secondary btn-navigate-form-step" type="button">
													{{ $t('general.next') }}&nbsp;
												</button>
										</div>
									</template>
								</section>
							</form>
						</div>

						<section v-if="registration_completed" id="step-4" class="form-step">
							<!-- Step 4 input fields -->
							<div v-if="registerStore.hasEmail" class="register-form-main">
								<h2>{{ $t('account.register.thank_you_for_your_registration') }}</h2>
							</div>
							<div v-else class="register-form-main">
								<h2>{{ $t('account.register.thank_you_for_your_registration_no_email_field') }}</h2>
							</div>
							<div class="form-btns">
								<button @click="closeModal()" class="btn btn-navigate-form-step btn-form-prev" type="button" style="cursor: pointer">{{ $t('account.register.close') }}</button>
							</div>
						</section>

						<div v-if="!registration_completed" class="login-option">
							<p>{{ $t('account.register.already_registered') }} <a @click="confirmModal( {show_login: true} )" style="cursor: pointer;">{{ $t('account.login.login') }}</a></p>
						</div>
					</div>
				</div>
			</div>
		</section>
	</template>

<style scoped>
@media screen and (min-width: 1041px) and (max-width: 3000px){
	.register-panel-right .login-option {
		position: unset;
		bottom: unset;
		margin-top: 25px!important;
		/* margin-right: -27%!important; */
	}
}
.form-btns {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}
html[dir="rtl"] .register-panel .register-panel-main .form-cross {
	right: unset;
	left: 30px;
}
html[dir="rtl"] .register-form-main .form-mobile-back {
	left: unset;
	right: 20px;
	transform: rotate(180deg);
}

.disabled-button {
	background: transparent;
	pointer-events: none;
	border: 1px solid #4E545D;
	color: #4E545D;
}


.register-panel .register-panel-main .register-panel-left .register-image-box .register-logo {
	align-items: center;
}
</style>