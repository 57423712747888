<script setup>
/* eslint-disable */
import { onMounted } from "vue"

import Header from "../components/Home/headerHome.vue"
import MobileMenu from "../components/Common/mobileMenu.vue"
import Footer from "../components/Common/Footer.vue"
import PromoCard from "../components/Home/PromoCard.vue"
import LiveNow from "../components/Home/LiveNow.vue"
import HeroBanner from "../components/Home/HeroBanner.vue"
import TodaysCoupons from "../components/Home/TodaysCoupons.vue"
import Games from "../components/Home/GamesCard.vue"
import { useRouter } from 'vue-router'
import commonSvgs from '@/assets/svgs/commonSvgs.json';
// import TopCompetitions from "../components/Home/competitions.vue"

import MyBetsSlips from "./Bets/MyBetsSlips.vue"
import { useCustomerStore } from "@/stores/customer";
import { useGeneralStore } from '@/stores/general';
import { useInitializeStore } from '@/stores/initialize';
import { useI18n } from 'vue-i18n';
import { useMenuStore } from "@/stores/menus";
import { openModal } from "@kolirt/vue-modal";
import Login from "@/components/modals/Login.vue";
import VerificationEmail from "@/components/modals/VerificationEmail.vue";

const { t } = useI18n();
const router = useRouter();
const generalStore = useGeneralStore();
const menuStore = useMenuStore();
const customerStore = useCustomerStore();
const initializeStore = useInitializeStore();

const goTo = (path) => {
	let login_required = (path.includes('/account') || path.includes('/casino/game/') || path.includes('/live-casino/table/'));
	let verification_required = (path.includes('/account') || path.includes('/casino/game/') || path.includes('/live-casino/table/'));

	if (login_required && !customerStore.isLoggedIn) openModal(Login).catch(() => {});
	else if (verification_required && !customerStore.hasVerifiedEmail) openModal(VerificationEmail).catch(() => {});
	else router.push(path);
}

onMounted(() => {
	document.title = generalStore.getGeneral.site_name + ' - ' +  t('general.home');
})
</script>

<template>
<Header></Header>

<!-- fixed mobile bottom menu-->
<div class="fixed-btm-main">
	<div class="container-full fix-padding">
		<MobileMenu></MobileMenu>
	</div>
</div>

<!-------- banner start--------->
<HeroBanner class="home_banner"></HeroBanner>
<!-------- banner end--------->

<!-- small boxes start -->

<section class="nav-boxes-main" v-if="menuStore.getLandingPageMenu.length > 0">
	<div class="container-full">
		<div class="nav-boxes-main">
			<div class="nav-box-item" v-for="menu_item in menuStore.getLandingPageMenu" @click="goTo(menu_item.click_url)">
				<p>{{ menu_item.name }}</p>
			</div>
		</div>
	</div>
</section>

<!-- small boxes end -->

<!---------live score start----------->

<section class="live-score-pannel">
	<div class="container-lg">
		<div class="title">
			<h2>{{$t('general.live_now')}}</h2>
		</div>
		<!-- score slider card start-->
		<div class="live-score-main">
			<LiveNow></LiveNow>
		</div>
		<!-- score slider card end-->
	</div>
</section>
<!---------live score end----------->


<!---------promo card start----------->
<section class="promo-sec-main">
	<div class="container-lg">
		<PromoCard></PromoCard>
	</div>
</section>

<!---------promo card end----------->

<!-- footer start -->
<MyBetsSlips v-if="initializeStore.isSportsbookEnabled"/>

<Footer></Footer>

<!-- footer end -->
</template>

<style scoped>
@media screen and (min-width: 320px) and (max-width: 767px) {
	.games-main-section {
		padding-top: 15px;
		padding-bottom: 20px
	}
	.game-section .container-lg {
		padding-right: 12px;
		padding-left: 12px;
	}
	.coupon-section-main .container-lg {
		padding-right: 12px;
		padding-left: 12px;
	}
}

.games-main-section h3 {
	text-align: center;
	text-transform: uppercase;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
	.games-main-section h3 {
		font-size: 20px;
	}

}

@media screen and (min-width: 992px) and (max-width: 1190px) {
	.games-main-section h3 {
		font-size: 18px;
	}
}

@media screen and (min-width: 992px) and (max-width: 1359px) {
	.games-main-section h3 {
		font-size: 20px;
	}
}

@media screen and (min-width: 1360px) and (max-width: 1439px) {
	.games-main-section h3 {
		font-size: 22px;
	}
}

@media screen and (min-width: 1440px) and (max-width: 1599px) {
	.games-main-section h3 {
		font-size: 24px;
	}
}
html[dir="rtl"] .fix-padding {
		padding-left: unset;
		padding-right: unset;
	}
</style>
