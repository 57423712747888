<script setup>
/* eslint-disable */
import { ref } from 'vue'
import { useMissionsStore } from '@/stores/missions';
import { useI18n } from 'vue-i18n';
import { closeModal } from '@kolirt/vue-modal'
import commonSvgs from '@/assets/svgs/commonSvgs.json';
import Loader from '@/components/Common/Loader.vue';
import { onMounted } from 'vue';

const missionsStore = useMissionsStore();
const { t } = useI18n();

const svg_data = commonSvgs;
const selected_mission = ref(null);

onMounted(() => {
	selected_mission.value = missionsStore.getSelectedMission;
});

</script>
<template>
	<div class="card" :style="selected_mission && selected_mission.can_cashout ? '' : { height: '315px' }">
		<Loader v-if="!selected_mission" />
		<template v-else>
			<div class="title">
				<img src="/assets/white_sport_icons/Archery.svg" width="28" height="28" alt="">
				<h4>{{ selected_mission.name }}</h4>
				<object v-html="svg_data.form_cross" @click="closeModal" style="cursor: pointer;"></object>
			</div>
			<div class="content">
				<h5>{{ t('missions.congratulations') }}</h5>
				<p>{{ t('missions.mission_completed') }}</p>
				<div class="progressbar-container">
					<div class="progress">100%</div>
				</div>

				<div class="warning" v-if="selected_mission.can_cashout">
					<img src="/assets/icons/warning.svg" width="16" height="16" alt="">
					<div>
						<p>{{ t('missions.mission_cancel_warning') }}.</p>
						<p>{{ t('missions.mission_outcome_warning') }}</p>
					</div>
				</div>
			</div>
			<div class="footer">
				<button class="btn-small btn-secondary" @click="closeModal">{{ t('account.register.close') }}</button>
			</div>
		</template>
	</div>
</template>
<style scoped>
	.card {
		background-color: #131C27;
		border-radius: 10px;
		padding: 20px;
		width: 25%;
		height: 370px;
	}
	.title {
		display: grid;
		grid-template-columns: 28px 1fr 28px;
		gap: 10px;
		align-items: center;
		border-bottom: 1px solid #4E545D;
		padding-bottom: 6px;
	}
	.title h4 {
		color: #ffffff;
		text-wrap: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.content {
		margin-top: 25px;
		padding-bottom: 20px;
		border-bottom: 1px solid #4E545D;
	}
	.content h5 {
		color: #ffffff;
		margin-bottom: 10px;
	}
	.content p {
		color: #ffffff;
		font-size: 15px;
	}
	.progressbar-container {
		background-color: #2D3745;
		width: 100%;
		border-radius: 15px;
		margin-bottom: 20px;
		margin-top: 10px;
	}
	.progress {
		color: white;
		text-align: right;
		font-size: 15px;
		border-radius: 15px;
		padding-right: 10px;
		width: 100%;
		background-color: #9DC137;
	}
	.warning {
		display: grid;
		grid-template-columns: 16px 1fr;
		gap: 10px;
		align-items: center;
	}
	.warning p {
		font-size: 11px;
	}

	.footer {
		margin-top: 30px;
		display: flex;
		justify-content: end;
	}

	@media screen and (min-width: 320px) and (max-width: 374px) {
		.card {
			width: 100%;
			height: 400px;
		}
	}

	@media screen and (min-width: 375px) and (max-width: 600px) {
		.card {
			width: 100%;
		}
	}

	@media screen and (min-width: 601px) and (max-width: 768px) {
		.card {
			width: 85%;
		}
	}

	@media screen and (min-width: 769px) and (max-width: 1024px) {
		.card {
			width: 85%;
		}
	}

	@media screen and (min-width: 1025px) and (max-width: 1280px) {
		.card {
			width: 50%;
		}
	}

	@media screen and (min-width: 1281px) and (max-width: 1700px) {
		.card {
			width: 35%;
		}
	}
</style>