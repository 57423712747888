<script setup>
// eslint-disable-next-line no-unused-vars
import { closeModal } from '@kolirt/vue-modal'
import { useLoginStore } from '@/stores/login'
import { useCustomerStore } from '@/stores/customer'
import Loader from '@/components/Common/Loader.vue'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import commonSvgs from '@/assets/svgs/commonSvgs.json'

const loginStore = useLoginStore()
const customerStore = useCustomerStore()
const { t } = useI18n()
const loading = ref(false)
const email_send = ref(false)
const title = ref(t('general.registration_modal_title'))
const text = ref(t('general.registration_check_your_email_account'))
const svgs_data = commonSvgs;

const sendEmail = () => {
	email_send.value = true
	loading.value = true

	loginStore.resendEmailVerification(customerStore.getCustomer.id).then((response) => {
		title.value = response.message
		text.value = t('email_verification.check_inbox')
		loading.value = false

	}).catch((error) => {
		title.value = error.data.error;
		text.value = null;
		loading.value = false
	})
}

</script>
<template>
	<div class="modal-outer verify-email-popup" ref="modal">
		<!-- overlay -->
		<div class="overlay"></div>
		<!-- modal -->
		<div class="modal">
			<button class="close" @click="closeModal()"  v-html="svgs_data.popup_cross"></button>

			<Loader v-if="loading" style="padding-top: 80px;"></Loader>

			<template v-if="!loading">
				<div class="modal-header">
					<h3>{{ title }}</h3>
				</div>
				<div class="password-reset-main">
					<div class="popup-message">
						<p>{{ text }}</p>
						<p v-if="!email_send" class="small-text">{{ $t('email_verification.did_not_recieve') }} <a @click="sendEmail()" style="cursor: pointer; color: #9DC137;">{{ $t('email_verification.resend') }}</a></p>
					</div>
					<button class="btn btn-secondary" type="button" @click="closeModal()">{{ $t('general.close') }}</button>
				</div>
			</template>
		</div>

	</div>
</template>