<!-- eslint-disable -->
<script setup>
import { ref, computed } from 'vue';
import { useLoginStore } from '@/stores/login';
import { useGeneralStore } from '@/stores/general';
import { useI18n } from 'vue-i18n';
import commonSvgs from '@/assets/svgs/commonSvgs.json';
import { useCustomerStore } from '@/stores/customer';
import { closeModal, confirmModal, openModal } from '@kolirt/vue-modal'
import { useCountriesStore } from '@/stores/countries';
import MobileCodeVerify from '@/components/modals/MobileCodeVerify.vue';
import FirstLogin from '@/components/modals/FirstLogin.vue';

const countriesStore = useCountriesStore();
const loginStore = useLoginStore();
const generalStore = useGeneralStore();
const { t } = useI18n();
const svg_data = commonSvgs;
const username = ref('');
const password = ref('');
const email = ref('');
const mobile_number = ref('');
const mobile_prefix = ref('81');
var error_message_email = ref(null);
var error_message_password = ref(null);
const error_message_forgot = ref(null);
const password_visibility = ref(false);
const loading = ref(false);
const forgot_password = ref(false);
const success_message_forgot = ref(null);
const customerStore = useCustomerStore();
const is_country_ghana = process.env.VUE_APP_COUNTRY == 'GHANA';
const account_unlock = ref(false);

const showLoginText = () => {
	if (process.env.VUE_APP_COUNTRY == 'GHANA' && process.env.VUE_APP_AUTH_USERNAME == 'username'){
		return t('account.login.mobile');
	} else if (process.env.VUE_APP_AUTH_USERNAME == 'username') {
		return t('account.login.username');
	} else if (process.env.VUE_APP_AUTH_USERNAME == 'email'){
		return t('account.account_details.email');
	} else {
		return t('account.login.email_or_username');
	}
}

const close = () => {
	closeModal();
	customerStore.redirect_after_login = {};
}

const login = () => {
	if (username.value != null && password.value != null && username.value != '' && password.value != ''){
		loading.value = true;
		let payload = { username: username.value, password: password.value };
		if (process.env.VUE_APP_AUTH_USERNAME == 'email') payload = { email: username.value, password: password.value }
		if (process.env.VUE_APP_AUTH_USERNAME == 'both') payload = { emailOrUsername: username.value, password: password.value }

		loginStore.submitLogin(payload).then( () => {
			location.reload();
		}).catch(error => {
			if (error.data.error && error.data.error === 'first_login') {
				closeModal();
				setTimeout(() => {
					openModal(FirstLogin, { mobile_number: Number(error.data.mobile_number), country_code: String(error.data.country_code), email: error.data.email }).then((data) => {}).catch(() =>{});
				}, 500);
			}
			error_message_password.value = error.data.message;
			loading.value = false;
		})
	} else {
		if (username.value == null || username.value == '') error_message_email.value = t('account.register.error_email_required');
		if (password.value == null || password.value == '') error_message_password.value = t('account.account_password.required');
	}

}

const isSendButtonDisabled = computed(() => {
	return email.value === null || email.value === '';
})

const isSendButtonDisabledMobile = computed(() => {
	return mobile_number.value === null || mobile_number.value === '';
})

const isLoginButtonDisabled = computed(() => {
	return username.value == null || username.value == '' || password.value == null || password.value == '';
})

const openAccountUnlock = () => {
	forgot_password.value = true;
	account_unlock.value = true
	mobile_number.value = null
	error_message_forgot.value = null
}

const openForgot = () => {
	forgot_password.value = true;
	account_unlock.value = false
	mobile_number.value = null
	error_message_forgot.value = null
}

const forgotPassword = () => {
	if (email.value != null && email.value != '') {
		error_message_forgot.value = null;
		success_message_forgot.value = null;
		loading.value = true;
		loginStore.forgotPassword(email.value).then(response => {
			success_message_forgot.value = response.message;
			loading.value = false;
		}).catch(error => {
			if (error.data.errors) error_message_forgot.value = error.data.errors.email[0];
			else error_message_forgot.value = error.data.message;
			loading.value = false;
		})
	} else {
		error_message_forgot.value = t('account.register.error_email_required');
	}
}

const forgotPasswordMobile = () => {

	if (mobile_prefix.value == null && mobile_prefix.value == '') {error_message_forgot.value = t('account.register.error_mobile_number_required');}
	else if (mobile_number.value == null && mobile_number.value == '') {error_message_forgot.value = t('account.register.error_mobile_number_required');}
	else if (!/^[0-9]+$/.test(mobile_number.value) && mobile_number.value) {error_message_forgot.value = t('account.register.error_only_numbers');}
	else {
		let payload = {
			mobile_number: mobile_number.value,
			mobile_country_id: mobile_prefix.value,
			account_unlock: account_unlock.value
		}

		error_message_forgot.value = null;
		success_message_forgot.value = null;
		loading.value = true;
		loginStore.forgotPasswordMobile(payload).then(response => {
			success_message_forgot.value = response.message;
			loading.value = false;

			openModal(MobileCodeVerify, { mobile_number: mobile_number.value, mobile_country_id: mobile_prefix.value, account_unlock: account_unlock.value }).then((data) => {
				if (data.show_login == false) closeModal();
			}).catch(() =>{});
		}).catch(error => {
			error_message_forgot.value = error.data.message;
			setTimeout(() => {
				error_message_forgot.value = null;
			}, 2000);
			loading.value = false;
		})
	}
}
</script>
<template>
	<section v-if="!forgot_password && !is_country_ghana" class="register-panel login-pannel">
		<div class="container-full">
			<div class="register-panel-main">
				<span class="form-cross">
					<a @click="closeModal" style="cursor: pointer;">
						<object v-html="svg_data.form_cross"></object>
					</a>
				</span>
				<div class="register-panel-left">
					<div class="register-image-box">
						<div class="register-logo">
							<img :src="generalStore.getGeneral.header_logo" alt="register-logo">
						</div>
						<div class="content">
							<h3>the best <span>betting</span> experience</h3>
						</div>
					</div>
				</div>
				<div class="register-panel-right">
					<div id="login-form" class="register-form-main">
						<h2>{{ $t('account.login.welcome') }}</h2>

						<!-- Step Wise Form Content -->
						<form class="register-outer-form" @keydown.enter="login()">
							<div  class="form-mobile-back-close">
								<a @click="closeModal" style="cursor: pointer;">
									<object v-html="svg_data.arrow_left"></object>
								</a>
							</div>
							
							<div class="register-inner-form">
								<div class="register-form-field">
									<label>{{showLoginText()}}</label>
									<input v-model="username" class="form-input" :placeholder="showLoginText()" type="text" required/>
									<span v-if="error_message_email" style="color: red;">{{ error_message_email }}</span>
								</div>
								<div class="register-form-field">
									<label>
										{{ $t('account.login.password') }}</label>
									<div class="pass-field">
										<input :type="password_visibility ? 'text' : 'password'" :placeholder="$t('account.login.password')" class="form-input form-input-password" name="password" autocomplete="off" v-model="password" required>
										<a class="togglePassword" @click="password_visibility = !password_visibility" >
											<img v-if="password_visibility" src="@/assets/svgs/eye.svg" alt="">
											<img v-else src="@/assets/svgs/eyeCross.svg" alt="">
										</a>
									</div>
									<img v-if="error_message_password" src="@/assets/svgs/cross.svg" alt=""/>&nbsp;
									<span v-if="error_message_password" style="color: red;" >{{ error_message_password }}</span>
								</div>
								<div class="login-other-options">
									<div class="register-age-check register-form-field">
									</div>
									<div class="forgot-password">
										<p @click="forgot_password = true">{{ $t('account.login.forgot_password') }}</p>
									</div>
								</div>
							</div>

							<div class="login-form-btn" v-if="!loading">
								<button :class="isLoginButtonDisabled ? 'btn' : 'btn btn-secondary' " type="button" step_number="2" @click="login()" :disabled="isLoginButtonDisabled">{{ $t('account.login.login') }}</button>
							</div>
							<div class="login-form-btn" v-else>
								<button class="btn btn-secondary " type="button" step_number="2"><div class="loader-main"></div></button>
							</div>
						</form>
					</div>

					<div class="login-option">
						<p>{{ $t('account.login.no_account') }} <a @click="confirmModal( {show_register: true} )" style="cursor: pointer;">{{ $t('account.register.register') }}</a>
						</p>
					</div>

				</div>
			</div>
		</div>
	</section>

	<section v-if="!forgot_password && is_country_ghana" class="register-panel login-pannel">
		<div class="container-full">
			<div class="register-panel-main">
				<span class="form-cross" >
					<a @click="close" style="cursor: pointer;">
						<object v-html="svg_data.form_cross"></object>
					</a>
				</span>
				<div class="register-panel-left">
					<div class="register-image-box">
						<div class="register-logo">
							<img :src="generalStore.getGeneral.header_logo" alt="register-logo">
						</div>
						<div class="content">
							<h3>the best <span>betting</span> experience</h3>
						</div>
					</div>
				</div>
				<div class="register-panel-right">
					<div id="login-form" class="register-form-main">
						<h2>{{ $t('account.login.welcome') }}</h2>

						<!-- Step Wise Form Content -->
						<form class="register-outer-form" @keydown.enter="login()">
							<a href="#" class="form-mobile-back-close">
								<a @click="closeModal" style="cursor: pointer;">
									<object v-html="svg_data.arrow_left"></object>
								</a>
							</a>
							<div class="register-inner-form">
								<div class="register-form-field">
									<label>{{showLoginText()}}</label>
									<input v-model="username" class="form-input" :placeholder="showLoginText()" type="text" required/>
									<span v-if="error_message_email" style="color: red;">{{ error_message_email }}</span>
								</div>
								<div class="register-form-field">
									<label>
										{{ $t('account.login.password') }}</label>
									<div class="pass-field">
										<input :type="password_visibility ? 'text' : 'password'" :placeholder="$t('account.login.password')" class="form-input form-input-password" name="password" autocomplete="off" v-model="password" required>
										<a class="togglePassword" @click="password_visibility = !password_visibility" >
											<img v-if="password_visibility" src="@/assets/svgs/eye.svg" alt="">
											<img v-else src="@/assets/svgs/eyeCross.svg" alt="">
										</a>
									</div>
									<img v-if="error_message_password" src="@/assets/svgs/cross.svg" alt=""/>&nbsp;
									<span v-if="error_message_password" style="color: red;" >{{ error_message_password }}</span>
								</div>
								<div class="login-other-options">
									<div class="register-age-check register-form-field">
									</div>
									<div class="forgot-password">
										<p @click="openForgot()">{{ $t('account.login.forgot_password') }}</p>
										<p @click="openAccountUnlock()" style="cursor: pointer;">{{ $t('account.login.locked_account') }}</p>
									</div>
								</div>
							</div>

							<div class="login-form-btn" v-if="!loading">
								<button :class="isLoginButtonDisabled ? 'btn' : 'btn btn-secondary' " type="button" step_number="2" @click="login()" :disabled="isLoginButtonDisabled">{{ $t('account.login.login') }}</button>
							</div>
							<div class="login-form-btn" v-else>
								<button class="btn btn-secondary " type="button" step_number="2"><div class="loader-main"></div></button>
							</div>
						</form>
					</div>

					<div class="login-option">
						<p>{{ $t('account.login.no_account') }} <a @click="confirmModal( {show_register: true} )" style="cursor: pointer;">{{ $t('account.register.register') }}</a>
						</p>
					</div>

				</div>
			</div>
		</div>
	</section>

	<section v-if="forgot_password && !is_country_ghana" class="register-panel login-pannel">
		<div class="container-full">
			<div class="register-panel-main">
				<span class="form-cross" >
					<a @click="closeModal" style="cursor: pointer;">
						<object v-html="svg_data.form_cross"></object>
					</a>
				</span>
				<div class="register-panel-left">
					<div class="register-image-box">

						<div class="register-logo">
							<img :src="generalStore.getGeneral.header_logo" alt="register-logo">
						</div>
						<div class="content">
							<h3>the best <span>betting</span> experience</h3>
						</div>
					</div>
				</div>

				<div class="register-panel-right">

					<div id="login-form" class="register-form-main forgot-form-main forgot-form-main">
						<h2>{{ $t('account.login.forgot_password') }}</h2>
						<div class="desc">
							<p>{{ $t('account.forgot_password.text') }}</p>
						</div>

						<!-- Step Wise Form Content -->
						<div class="register-outer-form" id="resetPassword" name="reset-password" @keydown.enter="forgotPassword()">
							<a href="#" class="form-mobile-back" @click="forgot_password = false">
								<img src="@/assets/images/arrow-prev.svg" alt="">
							</a>
							<a href="#" class="form-mobile-back-close">
								<a @click="closeModal" style="cursor: pointer;">
									<object v-html="svg_data.arrow_left"></object>
								</a>
							</a>
							<div class="register-inner-form">
								<div class="register-form-field">
									<label> {{$t('account.register.email_address')}}</label>
									<input v-model="email" class="form-input" :placeholder="$t('account.register.email_address')" type="email" name="email" required/>
									<span v-if="error_message_forgot" style="color: red;">{{ error_message_forgot }}</span>
									<span v-if="success_message_forgot" style="color: green;">{{ success_message_forgot }}</span>
								</div>
							</div>
							<div class="login-form-btn" v-if="!loading">
								<button :class="isSendButtonDisabled ? 'btn' : 'btn btn-secondary'" type="button" step_number="2" @click="forgotPassword()" :disabled="isSendButtonDisabled">{{$t('message.send')}}</button>
							</div>
							<div class="login-form-btn" v-if="!loading">
								<button class="btn" type="button" step_number="2" @click="forgot_password = false">{{$t('general.back')}}</button>
							</div>
							<div class="login-form-btn" v-else>
								<button class="btn" type="button" step_number="2">
									<div class="loader-main"></div>
								</button>
							</div>
						</div>
					</div>

					<div class="login-option">
						<p>{{ $t('account.login.no_account') }} <a @click="confirmModal( {show_register: true} )" style="cursor: pointer;">{{ $t('account.register.register') }}</a></p>
					</div>

				</div>
			</div>
		</div>
	</section>

	<section v-if="forgot_password && is_country_ghana" class="register-panel login-pannel">
		<div class="container-full">
			<div class="register-panel-main">
				<span class="form-cross" >
					<a @click="closeModal" style="cursor: pointer;">
						<object v-html="svg_data.form_cross"></object>
					</a>
				</span>
				<div class="register-panel-left">
					<div class="register-image-box">

						<div class="register-logo">
							<img :src="generalStore.getGeneral.header_logo" alt="register-logo">
						</div>
						<div class="content">
							<h3>the best <span>betting</span> experience</h3>
						</div>
					</div>
				</div>

				<div class="register-panel-right">

					<div id="login-form" class="register-form-main forgot-form-main forgot-form-main">
						<h2 v-if="account_unlock == false">{{ $t('account.login.forgot_password') }}</h2>
						<h2 v-if="account_unlock == true">{{ $t('account.login.unlock_account') }}</h2>
						<div class="desc">
							<p>{{ $t('account.forgot_password.text_mobile') }}</p>
						</div>

						<!-- Step Wise Form Content -->
						<div class="register-outer-form" id="resetPassword" name="reset-password" @keydown.enter="forgotPasswordMobile()">
							<a href="#" class="form-mobile-back" @click="forgot_password = false">
								<img src="@/assets/images/arrow-prev.svg" alt="">
							</a>
							<a href="#" class="form-mobile-back-close">
								<a @click="closeModal" style="cursor: pointer;">
									<object v-html="svg_data.arrow_left"></object>
								</a>
							</a>
							<div class="register-inner-form">
								<div class="register-form-field">
									<div class="row">
										<div class="prefix">
											<label>{{ $t('account.register.country') }} {{ $t('account.register.code') }}</label>
											<select v-model="mobile_prefix" @change="registerStore.validateMobilePrefix" name="" :style="{ cursor: is_country_ghana ? 'not-allowed' : 'pointer' }" class="form-input form-control" id="address-country" :disabled=is_country_ghana>
												<option :value="null" disabled>{{ $t('account.register.make_a_choice') }}</option>
												<option v-for="country in countriesStore.getCountries" :key="country.id" :value="country.id">+{{ country.mobile_prefix }}</option>
											</select>
										</div>
										<div>
											<label> {{$t('account.register.mobile')}}</label>
											<input v-model="mobile_number" class="form-input" :placeholder="$t('account.register.mobile')" type="text" name="mobile" required/>
											<span v-if="error_message_forgot" style="color: red;">{{ error_message_forgot }}</span>
											<span v-if="success_message_forgot" style="color: green;">{{ success_message_forgot }}</span>
										</div>
									</div>
								</div>
							</div>
							<div class="login-form-btn" v-if="!loading">
								<button :class="isSendButtonDisabledMobile ? 'btn' : 'btn btn-secondary'" type="button" step_number="2" @click="forgotPasswordMobile(), closeModal" :disabled="isSendButtonDisabledMobile">{{$t('message.send')}}</button>
							</div>
							<div class="login-form-btn" v-if="!loading">
								<button class="btn" type="button" step_number="2" @click="forgot_password = false">{{$t('general.back')}}</button>
							</div>
							<div class="login-form-btn" v-else>
								<button class="btn" type="button" step_number="2">
									<div class="loader-main"></div>
								</button>
							</div>
						</div>
					</div>

					<div class="login-option">
						<p>{{ $t('account.login.no_account') }} <a @click="confirmModal( {show_register: true} )" style="cursor: pointer;">{{ $t('account.register.register') }}</a></p>
						<p v-if="account_unlock == false" >{{ $t('account.login.locked_account') }} <a @click="openAccountUnlock" style="cursor: pointer;">{{ $t('account.login.unlock') }}</a></p>
						<p v-if="account_unlock == true" ><a @click="openForgot" style="cursor: pointer;">{{ $t('account.login.forgot_password') }}</a></p>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<style scoped>
html[dir="rtl"] .register-panel .register-panel-main .form-cross {
	right: unset;
	left: 30px;
}
html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .pass-field .togglePassword {
	right: unset;
	left: 25px;
}
html[dir="rtl"] .register-form-main .form-mobile-back {
	left: unset;
	right: 20px;
	transform: rotate(180deg);
}
html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .form-input {
	padding: 10px 15px 10px 15px;
}


@media screen and (max-height: 665px) {
	.login-pannel .register-panel-main .register-panel-right {
		padding-bottom: unset !important;
		min-height: 500px;
		overflow-y: scroll;
		overflow-x: hidden;
	}

	.register-panel.login-pannel .login-option {
		position: relative;
		margin-top: 10px !important;
		bottom: 20px !important;
	}

}
.row {
	display: grid;
	grid-template-columns: 33% 67%
}

.prefix {
	margin-right: 10px !important;
}

html[dir="rtl"] .prefix {
	margin-right: unset !important;
	margin-left: 10px !important;
}

.register-panel .register-panel-main .register-panel-left .register-image-box .register-logo {
	align-items: center;
}
</style>