<!-- eslint-disable -->
<script setup>

import { ref, computed, watch } from 'vue';
import { useFixtureStore } from '@/stores/fixture';
import { useLiveSportsStore } from '@/stores/live_sports';
import { useRouter } from 'vue-router';
import commonSvgs from '@/assets/svgs/commonSvgs.json'

const svg_data = ref(commonSvgs);
const router = useRouter();

const fixtureStore = useFixtureStore();
const liveSportsStore = useLiveSportsStore();

const goToCompetition = (fixture) => {
	router.push({ name: 'pre-sports-competition', params: { sport_id: fixture.sport_id, region_id: Number(fixture.region_id), competition_id: fixture.competition_id } } )
}
const is_muted = ref(false);

const toggleMute = () => {
	is_muted.value = !is_muted.value;
};

watch(() => fixtureStore.fixtureBook.statistics, (newValue, oldValue) => {
	// If Starting
	if (oldValue && oldValue.period == null && newValue && newValue.period == 'Q1') { 
		playSoundNotification();
	}
});

// If HalfTime
watch(() => fixtureStore.fixtureBook.scoreboard?.half_time_score, (newValue, oldValue) => {
	if (newValue && !oldValue) {
		playSoundNotification();
	}
});

// If Over Time
watch(() => fixtureStore.fixtureBook.scoreboard?.overtime, (newValue, oldValue) => {
	if (newValue && !oldValue) {
		playSoundNotification();
	}
});

const playSoundNotification = () => {
	if (!is_muted.value) { 
		const goalSound = new Audio('/assets/scoreboards/notification-sounds/start_and_half_time.mp3');
		goalSound.autoplay = true;
	}
}

</script>
<template>
	<div class="eventview-game-banner eventview-set_game-banner" v-if="fixtureStore.fixture != null">
	<div class="live-game-outer">
		<div class="live_score_Box">
			<div class="league-title">
				<h6 @click="goToCompetition(fixtureStore.fixtureBook)">{{ fixtureStore.fixtureBook.region_name + ' - ' + fixtureStore.fixtureBook.competition_name }}</h6>
			</div>
			<div @click="toggleMute" style="cursor: pointer;">
				<div v-if="is_muted" id="sound-icon" class="sound-notification">
					<i class='fas fa-volume-mute'></i>
				</div>
				<div v-else id="sound-icon" class="sound-notification">
					<i class='fas fa-volume-up'></i>
				</div>
			</div>
			<!-- score panel start-->
			<div class="league-score">
				<div class="set-top-info">
					<div class="live-badge" v-html="svg_data.live_badge"></div>
					<div class="playing-time" v-if="liveSportsStore.hasFixtureClock(fixtureStore.fixtureBook.sport_id)">
						<p>
							{{ fixtureStore.fixtureClockTime(fixtureStore.fixtureBook) }}
								<abbr>{{ fixtureStore.fixtureState(fixtureStore.fixtureBook) }}</abbr>
						</p>
						<span></span>
					</div>
					<div class="playing-time" v-else>
						<p> {{ fixtureStore.fixtureState(fixtureStore.fixtureBook) }}</p>
						<span></span>
					</div>
				</div>

				<div class="eventview-playing-info">
					<div class="playing-team-inner">
						<div class="playing-team" :class="fixtureStore.fixtureBook.scoreboard?.servis==1?'active':''">
							<h6>{{fixtureStore.fixtureBook.participants[0]}}</h6>
						</div>
						<div class="playing-score">
							<h3 v-if="fixtureStore.fixtureBook.scoreboard?.points">{{ fixtureStore.fixtureBook.scoreboard.points.p1}}</h3>
							<h3 v-else>{{ fixtureStore.fixtureBook.statistics.p1_score }}</h3>
						</div>
					</div>

					<div class="playing-team-inner">
						<div class="playing-team" :class="fixtureStore.fixtureBook.scoreboard?.servis==2?'active':''">
							<h6>{{fixtureStore.fixtureBook.participants[1]}}</h6>
						</div>
						<div class="playing-score">
							<h3 v-if="fixtureStore.fixtureBook.scoreboard?.points">{{ fixtureStore.fixtureBook.scoreboard.points.p2}}</h3>
							<h3 v-else>{{ fixtureStore.fixtureBook.statistics.p2_score }}</h3>
						</div>
					</div>
				</div>
				<div class="scorecard-outer">
					<div class="scorecard-inner-main">
						<div class="scorecard-inner">
							<div class="team-name">
							</div>
							<div class="scorecard-score-outer">
								<div class="scorecard-score-inner" v-if="fixtureStore.fixtureBook.scoreboard?.quarter_1">
									<Popper arrow hover openDelay="100" closeDelay="100" placement="top">
										<h6 :class="fixtureStore.fixtureBook.statistics.period == 'Q1' ? 'active' : '' ">{{ $t('scoreboard.quarter_short') }}1</h6>
											<template #content>
												<div class="tooltip"> {{ $t('scoreboard.quarter') }} 1 </div>
											</template>
									</Popper>
								</div>
								<div class="scorecard-score-inner" v-if="fixtureStore.fixtureBook.scoreboard?.quarter_2">
									<Popper arrow hover openDelay="100" closeDelay="100" placement="top">
										<template #content>
											<div class="tooltip"> {{ $t('scoreboard.quarter') }} 2 </div>
										</template>
										<h6 :class="fixtureStore.fixtureBook.statistics.period == 'Q2' ? 'active' : '' ">{{ $t('scoreboard.quarter_short') }}2</h6>
									</Popper>
								</div>
								<div class="scorecard-score-inner" v-if="fixtureStore.fixtureBook.scoreboard?.half_time_score">
									<Popper arrow hover openDelay="100" closeDelay="100" placement="top">
										<h6>{{ $t('scoreboard.half_time_short') }}</h6>
										<template #content>
											<div class="tooltip"> {{ $t('scoreboard.half_time') }} </div>
										</template>
									</Popper>
								</div>
								<div class="scorecard-score-inner" v-if="fixtureStore.fixtureBook.scoreboard?.quarter_3">
									<Popper arrow hover openDelay="100" closeDelay="100" placement="top">
										<template #content>
											<div class="tooltip"> {{ $t('scoreboard.quarter') }} 3</div>
										</template>
									<h6 :class="fixtureStore.fixtureBook.statistics.period == 'Q3' ? 'active' : '' ">{{ $t('scoreboard.quarter_short') }}3</h6>
									</Popper>
								</div>
								<div class="scorecard-score-inner" v-if="fixtureStore.fixtureBook.scoreboard?.quarter_4">
									<Popper arrow hover openDelay="100" closeDelay="100" placement="top">
										<template #content>
											<div class="tooltip"> {{ $t('scoreboard.quarter') }} 4</div>
										</template>
										<h6 :class="fixtureStore.fixtureBook.statistics.period == 'Q4' ? 'active' : '' ">{{ $t('scoreboard.quarter_short') }}4</h6>
									</Popper>
								</div>
								<div class="scorecard-score-inner" v-if="fixtureStore.fixtureBook.scoreboard?.overtime">
									<Popper arrow hover openDelay="100" closeDelay="100" placement="top">
										<template #content>
											<div class="tooltip"> {{ $t('scoreboard.overtime') }}</div>
										</template>
										<h6 :class="fixtureStore.fixtureBook.statistics.period == 'overtime' ? 'active' : '' ">{{ $t('scoreboard.overtime_short') }}</h6>
									</Popper>
								</div>
								<div class="scorecard-score-inner" v-if="fixtureStore.fixtureBook.scoreboard?.extra_time">
									<Popper arrow hover openDelay="100" closeDelay="100" placement="top">
										<template #content>
											<div class="tooltip"> {{ $t('scoreboard.extra_time') }}</div>
										</template>
										<h6 :class="fixtureStore.fixtureBook.statistics.period == 'extra_time' ? 'active' : '' ">{{$t('scoreboard.extra_time')}}</h6>
									</Popper>
								</div>
							</div>
						</div>

						<div class="scorecard-inner">
							<div class="team-name">
								<h5>{{fixtureStore.fixtureBook.participants[0]}}</h5>
							</div>
							<div class="scorecard-score-outer">
								<div class="scorecard-score-inner" v-if="fixtureStore.fixtureBook.scoreboard?.quarter_1">
									<h6 :class="fixtureStore.fixtureBook.statistics.period == 'Q1' ? 'active' : '' ">{{ fixtureStore.fixtureBook.scoreboard?.quarter_1.p1 }}</h6>
								</div>
								<div class="scorecard-score-inner" v-if="fixtureStore.fixtureBook.scoreboard?.quarter_2">
									<h6 :class="fixtureStore.fixtureBook.statistics.period == 'Q2' ? 'active' : '' ">{{ fixtureStore.fixtureBook.scoreboard?.quarter_2.p1 }}</h6>
								</div>
								<div class="scorecard-score-inner" v-if="fixtureStore.fixtureBook.scoreboard?.half_time_score">
									<h6>{{ fixtureStore.fixtureBook.scoreboard?.half_time_score.p1 }}</h6>
								</div>
								<div class="scorecard-score-inner" v-if="fixtureStore.fixtureBook.scoreboard?.quarter_3">
									<h6 :class="fixtureStore.fixtureBook.statistics.period == 'Q3' ? 'active' : '' ">{{ fixtureStore.fixtureBook.scoreboard?.quarter_3.p1 }}</h6>
								</div>
								<div class="scorecard-score-inner" v-if="fixtureStore.fixtureBook.scoreboard?.quarter_4">
									<h6 :class="fixtureStore.fixtureBook.statistics.period == 'Q4' ? 'active' : '' ">{{ fixtureStore.fixtureBook.scoreboard?.quarter_4.p1 }}</h6>
								</div>
								<div class="scorecard-score-inner" v-if="fixtureStore.fixtureBook.scoreboard?.overtime">
									<h6 :class="fixtureStore.fixtureBook.statistics.period == 'overtime' ? 'active' : '' ">{{ fixtureStore.fixtureBook.scoreboard?.overtime.p1 }}</h6>
								</div>
								<div class="scorecard-score-inner" v-if="fixtureStore.fixtureBook.scoreboard?.extra_time">
									<h6 :class="fixtureStore.fixtureBook.statistics.period == 'extra_time' ? 'active' : '' ">{{ fixtureStore.fixtureBook.scoreboard?.extra_time.p1 }}</h6>
								</div>
							</div>
						</div>

						<div class="scorecard-inner">
							<div class="team-name">
								<h5>{{fixtureStore.fixtureBook.participants[1]}}</h5>
							</div>
							<div class="scorecard-score-outer">
								<div class="scorecard-score-inner" v-if="fixtureStore.fixtureBook.scoreboard?.quarter_1">
									<h6 :class="fixtureStore.fixtureBook.statistics.period == 'Q1' ? 'active' : '' ">{{ fixtureStore.fixtureBook.scoreboard?.quarter_1.p2 }}</h6>
								</div>
								<div class="scorecard-score-inner" v-if="fixtureStore.fixtureBook.scoreboard?.quarter_2">
									<h6 :class="fixtureStore.fixtureBook.statistics.period == 'Q2' ? 'active' : '' ">{{ fixtureStore.fixtureBook.scoreboard?.quarter_2.p2 }}</h6>
								</div>
								<div class="scorecard-score-inner" v-if="fixtureStore.fixtureBook.scoreboard?.half_time_score">
									<h6>{{ fixtureStore.fixtureBook.scoreboard?.half_time_score.p2 }}</h6>
								</div>
								<div class="scorecard-score-inner" v-if="fixtureStore.fixtureBook.scoreboard?.quarter_3">
									<h6 :class="fixtureStore.fixtureBook.statistics.period == 'Q3' ? 'active' : '' ">{{ fixtureStore.fixtureBook.scoreboard?.quarter_3.p2 }}</h6>
								</div>
								<div class="scorecard-score-inner" v-if="fixtureStore.fixtureBook.scoreboard?.quarter_4">
									<h6 :class="fixtureStore.fixtureBook.statistics.period == 'Q4' ? 'active' : '' ">{{ fixtureStore.fixtureBook.scoreboard?.quarter_4.p2 }}</h6>
								</div>
								<div class="scorecard-score-inner" v-if="fixtureStore.fixtureBook.scoreboard?.overtime">
									<h6 :class="fixtureStore.fixtureBook.statistics.period == 'overtime' ? 'active' : '' ">{{ fixtureStore.fixtureBook.scoreboard?.overtime.p2 }}</h6>
								</div>
								<div class="scorecard-score-inner" v-if="fixtureStore.fixtureBook.scoreboard?.extra_time">
									<h6 :class="fixtureStore.fixtureBook.statistics.period == 'extra_time' ? 'active' : '' ">{{ fixtureStore.fixtureBook.scoreboard?.extra_time.p2 }}</h6>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>

	</div>
</div>
</template>
<style scoped>
svg.svg-inline--fa.fa-volume-up.fa-w-18 {
	position: absolute;
	right: 10px;
	bottom: 160px;
	color: #fff;
}
svg.svg-inline--fa.fa-volume-mute.fa-w-16  {
	position: absolute;
	right: 10px;
	bottom: 160px;
	color: #fff;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
	svg.svg-inline--fa.fa-volume-up.fa-w-18 {
		bottom: 146px;
	}
	svg.svg-inline--fa.fa-volume-mute.fa-w-16  {
		bottom: 146px;
	}
}
@media screen and (min-width: 320px) and (max-width: 767px) {
	svg.svg-inline--fa.fa-volume-up.fa-w-18 {
		bottom: 131px;
	}
	svg.svg-inline--fa.fa-volume-mute.fa-w-16  {
		bottom: 131px;
	}
}
html[dir="rtl"] svg.svg-inline--fa.fa-volume-up.fa-w-18 { 
		right: unset;
		left: 10px
}
html[dir="rtl"] svg.svg-inline--fa.fa-volume-mute.fa-w-16 { 
		right: unset;
		left: 10px
}
.sports-main .eventview-pannel .sports-center-content .eventview-game-banner.eventview-set_game-banner .live-game-outer .live_score_Box .league-score .scorecard-outer .scorecard-inner-main .scorecard-inner .scorecard-score-outer .scorecard-score-inner:first-child h6 {
	color: unset;
}
.tooltip {
	width: 50px;
	background-color: #9DC137;
	padding: 5px 0;
	text-align: center;
	border: 1px solid #9DC137;
	border-radius: 50px !important;
	font-size: 10px;
	color:#ffffff
}
:deep(.popper) {
	background-color: #9DC137;
	padding: 20px;
	border-radius: 20px;
	font-weight: bold;
	margin-right: 30px;
}

:deep(.popper #arrow::before) {
	background: #9DC137;
	left: 0px;
}
:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
	background: #9DC137;
}
</style>